export const environment = {
    production: true,
    apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFvam9zZ3JmaWF1a2dzbWdocXNwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTYxODk2ODgsImV4cCI6MjAxMTc2NTY4OH0.9lwLyBvhQYZpxpJhjHnDe4ZO6g19Hy20JoifSTRBfSI",
    apiUrl: "https://qojosgrfiaukgsmghqsp.supabase.co",
    shortName: "GoS",
    longName: "Gemischtes Orchester",
    showTeachers: false,
    symphonyImage: true,
    withExcuses: true,
    withSignout: true,
    isChoir: false,
};